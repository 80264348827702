import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

// styles

const AboutWrapper = styled.div`
  margin: 0 auto;
  max-width: 1220px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 36px;
  align-items: center;


  @media (max-width:640px) {
    width: 85vw;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`
const AboutImage = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;

  img {
    width: 100%;
    opacity: 0;
    animation: ResponsiveEmbedAnimation 3s .5s forwards cubic-bezier(0.2, 0.8, 0.2, 1);

    @media (max-width:640px) {
      width: 43%;
      display: block;
      margin: 0 auto;
    }
  }

  
`
const AboutBio = styled.div`
  grid-column-start: 2;
  grid-column-end: 5;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
    margin-top: 6px;
  }

  h2 {

    @media (max-width:640px) {
      text-align: center;
      line-height: 1.2;
      font-size: 24px;
    }
  }

  p {

    @media (max-width:640px) {
      font-weight: 500;
      font-size: 18px;
    }
  }

  p.linkto {

    @media (max-width:640px) {
     text-align: center;
     font-weight: 600;
     font-size: 21px;
    }
  }
`

const About = () => (
    <Layout>
      <Seo title="About" />
      <div className="About">
      <h1 className="hideMe">About</h1>
      <AboutWrapper>
          <AboutImage>
          <StaticImage
              src="../images/about-dan.png"
              alt="Grayscale headshot of Dan Hanasono on a colorful gradient circle"
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="blurred"
          />
          </AboutImage>
          <AboutBio>
          <h2>dan hanasono (he/him/his)</h2>
          <p className="aboutDetails"><span role="img" aria-label="hand wave emoji">👋🏼</span> Hey there! I’m Dan Hanasono, a Senior Product Designer, cross-functional team leader, and design advocate based in Los Angeles. I came into product from years of branding and web design largely in B2B industries: food and beverage, commercial and residential real estate, private education, transportation, and agriculture. Most recently, I worked on a large scale data and analytics platform and corresponding design system in the recovered paper industry.</p>
          <p className="aboutDetails linkto">Drop me a <a href="mailto:dan.hanasono@gmail.com">line</a>, view my <Link to="/work/">work</Link>, and dig deeper <Link to="/sheesh/">here</Link>.</p>
          <p className="aboutDetails">
              <span role="img" aria-label="hand drafting emoji">✍🏼</span> senior product designer<br />
              <span role="img" aria-label="seedling emoji">🌱</span> intersectional environmentalist<br />
              <span role="img" aria-label="heart emoji">♥️</span> anti-racist asian american<br />
              <span role="img" aria-label="bike emoji">🚲</span> bike/transportation equity advocate<br />
              <span role="img" aria-label="location pin emoji">📍</span>los angeles</p>
          {/* <p>Site built with React using Gatsby.js, with the help of plugins: lottie-player, styled-components, google-analytics, gatsby-plugin-image, react-helmet, gatsby-plugin-smoothscroll, and react-responsive-embed. Designed in Figma, FigJam and WFH whiteboard. Launched and updated using Netlify. Fonts are open-source and free Alpino and Sentinent. Spacing and Type size are largely fluid ranging in sizes based on the Fluid Space and Type Calculator. Screenshots were sized down with tinypng.com, but then turned up with gatsby image. This is my first React web app built to design and learn! </p> */}
          
          </AboutBio>
      </AboutWrapper>
      </div>
    </Layout>
  )

export default About